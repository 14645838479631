<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <b-row class="match-height">
            <b-col lg="12" md="12">
        
                <b-card title="Add Equipment">

                    <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                        <div class="alert-body">
                            {{error_message}}
                        </div>
                    </b-alert>
                
                    <b-form @submit.prevent="formSubmit">
                        <b-row>
                            <b-col md="12">

                                <div class="fsmTable table-responsive" style="margin-bottom: 0px !important;">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" style="width: 95%">Equipment Name <span class="text-danger">*</span></th>
                                                <th scope="col" style="width: 5%"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(fm, index) in form" :key="index">
                                                <td>
                                                    <b-form-input placeholder="3 to 50 characters" v-model="fm.equipment_name" />
                                                </td>
                                                <td class="text-center">
                                                    <b-button variant="danger" @click="removeRow(index);">-</b-button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <!-- <b-row>
                                    <b-col md="10">Equipment Name<span class="text-danger">*</span></b-col>
                                    <b-col md="2"></b-col>
                                </b-row>

                                <b-col v-for="(fm, index) in form" :key="index" class="mt-1 mobile_dispaly_inline mobile_pdng_lft_rgt_zero desktop_pdng_lft_rgt_zero desktop_display_flex">
                                    <b-col md="11" class="mobile_pdng_lft_rgt_zero mobile_md_eleven desktop_pdng_lft_rgt_zero">
                                        
                                            <b-col md="0">
                                                <b-form-input placeholder="3 to 50 characters" v-model="fm.equipment_name" />
                                            </b-col>
                                        
                                    </b-col>
                                    <b-col md="1" class="text-center mobile_pdng_lft_rgt_zero mobile_md_one md_one_desktop">
                                        <b-button variant="danger" @click="removeRow(index);">-</b-button>
                                    </b-col>
                                </b-col> -->

                                <b-row>
                                    <b-col md="11" class="text-center">
                                        <hr>
                                        <b-button class="mt-1 under_construct_mobile_add_more_btn" variant="primary" @click="addRow">Add More</b-button>
                                    </b-col>
                                </b-row>
                                            
                                <b-row>
                                    <b-col>
                                        <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "warning" class = "mr-1">
                                            Submit
                                        </b-button>

                                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$router.push({ name:'equipments'})">
                                            Cancel
                                        </b-button>
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>

                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import {
        BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile,BBreadcrumb
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import { GET_API, POST_API } from "../../../store/actions.type"
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import vSelect from 'vue-select';

    export default {
        components: {
            BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, VueTimepicker, vSelect, BFormFile,BBreadcrumb
        },

        directives: {
            Ripple,
        },

        data() {
            return {        
                error_message:null,
                showDismissibleAlert:false,

                form :[
                    { 
                        equipment_name : ''
                    }
                ],
            }
        },

        methods : {
            formSubmit(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        items : this.form,
                    },
                    api : '/api/add-equipment'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message        = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;

                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;

                        this.successAlert().then((result) => {
                            // this.$router.go(-1);
                            this.$router.push({ name:'equipments'})
                        });
                    }
                });
            },

            addRow(){
                this.form.push({
                    essential_name : '',
                })
            },

            removeRow(index) {
                if(this.form.length > 1){
                    this.form.splice(index, 1);
                }
            },
            breadCrumb(){
              var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
              },{
                to:{name:'custom-forms'},
                text: 'Custom Forms',
              },{
                to:{name:'monthly-inspection-checklist'},
                text:'Add Monthly Inspection Checklist'
              },{
                to:{name:'equipments'},
                text:'Equipments',
              },{
                to:null,
                text: 'Add Equipment',
                active:true
              }];
              return item;
            }
        },
    }
</script>
